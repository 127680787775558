/* #region Theme */

:root {
    --font-size: 20px;
    --color-dark: #0a0c10;
    --color-dark-shaded: #0a0c1044;
    --color-dark-grey: #0e1116;
    --color-grey: #171b21;
    --color-light-grey: #2f405c;
    --color-light-grey-shaded: #2f405cab;
    --color-light: #93a3bc;
    --color-lighter: #93a3bc40;
    --color-highlight: #1553b7;
    --color-highlight2: #246ee5;
    --color-success: #00a676;
    --color-error: #d70036;
    --color-error-light: #d70036dd;
    --border-radius-1: 0.8rem;
    --border-radius-2: 1.3rem;
    --border-radius-3: 2.1rem;
    --size-1: 0.1rem;
    --size-2: 0.2rem;
    --size-3: 0.3rem;
    --size-4: 0.5rem;
    --size-5: 0.8rem;
    --size-6: 1.3rem;
    --size-7: 2.1rem;
    --size-8: 3.4rem;
    --size-9: 5.5rem;

    /* #region highlight.js */

    --selection: #0affba5d;
    --background: rgba(224, 146, 58, 0);
    --text: #dae7fb;
    --keyword: #7faaf0;
    --number: var(--color-success);
    --string: #fff1ad;
    --title: #9e4ee8;
    --comment: #7f7f7f;
    --function: #e0923a;
    --built_in: #e25158;
    --params: #e25158;

    /* #endregion highlight.js */
}

/* #endregion */

/* disables tooltip of ion-icons */
ion-icon {
    pointer-events: none;
}

/* #region form - inputs */

.got-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3px 5px 6px 0;
    padding-bottom: 3px;
    height: 35px;
    border: 1px solid var(--color-light-grey-shaded);
    border-radius: var(--size-5);
    width: fit-content;
}

.got-input-container:hover {
    border-color: var(--color-light);
}

.got-input-container input {
    padding-left: 10px;
    color: var(--text);
    width: 200;
    background-color: transparent;
}

.got-input-container input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.got-input-container button:hover {
    color: var(--color-highlight2);
}

.got-input-container ion-icon {
    margin-top: 4px;
}

.got-input-container :nth-child(n) {
    border-radius: 0px 0px 0px 0px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: transparent;
}

.got-input-container :last-child {
    border-radius: 0px var(--size-5) var(--size-5) 0px;
    margin-left: 0px;
    margin-top: 4px;
    margin-right: 4px;
}

.got-input-container :only-child {
    border-radius: var(--size-5) var(--size-5) var(--size-5) var(--size-5);
}

/* #endregion */

/* #region main */
body {
    font-size: var(--font-size);
    overflow: hidden;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-grey);
}

/* body ::-webkit-scrollbar {
    width: 6px;
} */

body ::-webkit-scrollbar-track {
    border-radius: 3px;
    display: none;
}

body ::-webkit-scrollbar-thumb {
    background: #0000004d;
    border-radius: 3px;
}

body ::-webkit-scrollbar-thumb:hover {
    background: #0000008d;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* #endregion */

/* #region basics */
button {
    color: var(--color-light);
    background-color: var(--color-dark);
    border-radius: var(--size-5);
    border: 1px solid var(--color-light-grey-shaded);
    margin: 5px;
    padding: 5px;
}

button:active {
    background-color: var(--color-light-grey);
}

.highlight a,
.highlight a:visited,
.highlight {
    color: var(--color-highlight2);
}

.success a,
.success a:visited,
.success {
    color: var(--color-success);
}

.error a,
.error a:visited,
.error {
    color: var(--color-error-light);
}

.auth-error {
    margin: 0 0 8px 0;
    color: var(--color-error-light);
}

input {
    color: var(--color-dark-grey);
    border-radius: var(--size-5);
    border: none;
    margin: 5px;
    padding: 5px;
}

/* input:focus {
    -webkit-box-shadow: inset 1px 1px 2px 0px var(--color-highlight), inset -1px -1px 2px 0px var(--color-highlight);
    box-shadow: inset 1px 1px 2px 0px var(--color-highlight), inset -1px -1px 2px 0px var(--color-highlight);
} */

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

span {
    cursor: pointer;
}

/* #endregion */

/* #region functional */
.no-pointer-events {
    pointer-events: none;
}

/* #endregion */

/* #region app */
.app {
    height: 100vh;
}

.app .user-info {
    z-index: 5000;
    position: absolute;
    top: 5px;
    right: 4px;
}

.app .user-info button {
    padding: 0;
    border: none;
    background-color: transparent;
    transition: 0.1s ease;
    cursor: pointer;
}

.app .user-info button:hover {
    color: var(--color-highlight2);
}

/* #endregion */

/* #region Footnotes */

.foot-notes {
    position: absolute;
    bottom: 0;
    display: flex;
    height: 30px;
    width: 100vw;
    line-height: 30px;
    text-align: center;
    background-color: var(--color-dark-grey);
    color: var(--color-light);
    z-index: 1;
    border-top: 1px solid var(--color-light-grey);
}

.foot-notes .logo {
    display: inline;
    margin-left: 10px;
    margin-right: auto;
    width: 20%;
    height: 100%;
    text-align: left;
    color: white;
    text-decoration: none;
}

.app .foot-notes .user-info {
    display: inline-block;
    margin-left: auto;
    margin-right: 10px;
    position: initial;
    width: auto;
    height: 30px;
}

.app .foot-notes .user-info button {
    text-align: center;
    margin: 0;
    height: 100%;
    width: auto;
    border: none;
    background-color: var(--color-dark-grey);
}

/* #endregion */

/* #region Graph */

.graph-view {
    flex: 1;
}

.graph-icons {
    font-size: var(--size-7);
}

.graph-icons.logout {
    color: var(--text);
}

/* #endregion */

/* #region other shit */

:focus {
    outline: none;
}

#graph {
    flex: 1;
    width: 100vw;
    height: 100vh;
}

/* #endregion */

/* #region AUTH SCREEN  */

.centerContainer {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0vh;
    right: 30vw;
}

.centerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: 65vh;
    background-color: var(--color-dark);
    border-style: none;
    border-radius: var(--border-radius-2);
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.5);
    transition: 1s ease;
}

.centerBox:hover {
    box-shadow: 0px 0px 30px 0px var(--color-highlight);
}

.centerBox .loginForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centerBox .logo {
    color: white;
    align-self: center;
    font-size: var(--size-8);
    font-weight: 100;
}

.centerBox h1 {
    color: var(--color-light);
}

.centerBox .authscreen-title {
    margin-bottom: 36px;
    font-weight: 400;
    color: var(--color-light);
}

.centerBox input {
    border-radius: var(--border-radius-2);
    margin-bottom: var(--size-5);
}

.centerBox input:focus {
    -webkit-box-shadow: inset 1px 1px 2px 0px var(--color-light),
        inset -1px -1px 2px 0px var(--color-light);
    box-shadow: inset 1px 1px 2px 0px var(--color-light), inset -1px -1px 2px 0px var(--color-light);
}

.centerBox input {
    background-color: var(--color-lighter);
    color: whitesmoke;
}

.auth-input {
    width: 70%;
    margin-left: 0px;
    padding-left: 12px;
    height: 35px;
    margin-bottom: 3px;
    border-radius: 5px;
}

.button-blue {
    height: 35px;
    width: 100%;
    font-weight: bold;
    text-align: center;
    border-radius: var(--border-radius-2);
    cursor: pointer;
    color: var(--color-highlight);
    border: 1px solid var(--color-highlight);
    margin-bottom: 1px;
    transition: 0.2s ease;
}

.button-blue:hover {
    border: 1px solid var(--color-highlight2);
    color: var(--color-highlight2);
}

.button-quiet {
    height: 35px;
    width: 100%;
    font-weight: bold;
    text-align: center;
    border-radius: var(--border-radius-2);
    cursor: pointer;
    border-width: 0;
    font-weight: normal;
    margin-bottom: 1px;
}

.button-quiet:hover {
    border-width: 1px;
}

/* #endregion */

/* #region graph controls */
.graph-control-icons {
    font-size: var(--size-6);
}

.graph-control-icons:hover {
    color: var(--color-highlight2);
}

.logo-container {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    width: 150px;
}

.graph-logo {
    text-align: center;
    height: 100%;
    color: var(--color-light);
    font-size: var(--size-7);
    font-weight: 200;
}

.graph-logo2 {
    text-align: center;
    height: 100%;
    color: var(--color-highlight);
    font-size: var(--size-7);
    font-weight: 200;
}

.control-container {
    padding: 5px 5px 10px 4px;
    top: 0;
    background-color: var(--color-dark-grey);
    border-bottom-right-radius: var(--size-5);
    position: absolute;
    max-width: 250px;
    left: 0;
    z-index: 3;
    width: 36%;
    /* Border on oder off */
    border-right: 1px solid var(--color-light-grey-shaded);
    border-bottom: 1px solid var(--color-light-grey-shaded);
    margin-bottom: 3px;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.control-container p {
    margin-top: 6;
    margin-bottom: 6;
}

.control-container .dropdown {
    font-size: 1rem;
}
.control-container .dropdown input {
    background-color: transparent;
    color: var(--text);
}

.query-container {
    margin: 0 auto;
}

.query-container form {
    margin-bottom: 0;
}

.query-container * button {
    color: darkgrey;
    background-color: var(--color-dark-grey);
    border-radius: var(--size-5);
    border: 0px solid #6f788b;
    margin: 5px;
    padding: 5px;
}

.control-container .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0px auto;
}

.control-container .button-group .button-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.control-container .button-group .button-container button {
    background-color: var(--color-dark-grey);
    border-radius: var(--size-5);
    border: 0px solid #6f788b;
    margin: 0px;
    padding: 5px;
}

.control-container .button-group .button-container button:active {
    color: var(--color-highlight);
    border-radius: var(--size-5);
    border: 0px solid #6f788b;
    margin: 0px;
    padding: 5px;
}

.control-container .button-group .button-container button:hover {
    color: var(--color-highlight);
}

button.control-button-active {
    background-color: white;
    color: var(--color-highlight);
    border-radius: var(--size-5);
    border: 0px solid #6f788b;
    margin: 0px;
    padding: 5px;
}

.sub-control-container {
    background-color: var(--color-dark-grey);
    border-radius: 3px;
}

.sub-control-container div.toggle-container {
    max-height: 0;
    margin: 0;
    overflow: visible;
    transition: all 0.5s ease;
}

.sub-control-container div.toggle-container.active {
    display: block;
    max-height: 600px;
}

.sub-control-container div.toggle-container.active > * {
    display: flex;
    max-height: 600px;
}

.sub-control-container div.toggle-container > * {
    display: none;
}

.sub-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 350px;
}

.select-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 350px;
}

.select-controls input {
    border-radius: 0;
}

.select-controls input:focus {
    box-shadow: none;
}

.menu-indicator {
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-highlight);
    margin-left: 12;
    margin-top: 15;
    cursor: default;
}

/* #endregion */

/* #region flyout */
.flyout {
    color: var(--text);
    overflow: hidden;
}

.v-divider {
    border-left: 1px solid var(--color-light-grey-shaded);
    margin: 4px 0 4px 0;
    height: 70%;
}

.flyout ion-icon {
    font-size: var(--size-7);
}

.flyout-area ion-icon {
    font-size: var(--size-6);
}

.flyout-area .got-input-container ion-icon {
    padding: 1px;
}

.flyout .open-close-buttons {
    z-index: 100;
    background-color: transparent;
    border: none;
}

.flyout .open-close-buttons button {
    margin: 0;
    padding: 0;
    z-index: 100;
    height: 41px;
    width: 41px;
    border-radius: 10px;
    background-color: transparent;
    border: none;
    transition: 0.1s ease;
    cursor: pointer;
    color: var(--color-light-grey);
}

.flyout .open-close-buttons button:hover {
    color: var(--color-highlight2);
}

.flyout .open-close-buttons .open-button {
    color: var(--text);
}

.flyout .open-close-buttons .open-button:hover {
    color: var(--color-highlight2);
}

.flyout .fullscreen {
    display: flex;
    justify-content: center;
}

.flyout .flyout-container {
    z-index: 100;
    height: 100vh;
    position: absolute;
    background-color: var(--color-grey);
    box-sizing: border-box;
}

.flyout .container-border {
    border-left: 1px solid var(--color-light-grey-shaded);
    height: 100%;
}

.flyout .flyout-bar {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0px;
    height: 100vh;
}

.flyout .flyout-area {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.flyout .flyout-area .top-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.flyout .state-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.flyout .state-buttons button {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flyout .state-buttons .button-icon {
    display: flex;
    align-items: center;
}

.flyout .state-buttons .button-icon p {
    margin: 0;
}

.flyout .state-buttons .active,
.flyout .state-buttons button:active {
    color: var(--color-highlight);
}

.flyout .state-buttons button {
    margin: 0px;
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
    width: 130;
}

.flyout .content {
    min-height: 100%;
}

.flyout .user-views {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10;
    margin-bottom: 10;
}

.flyout .user-views .view-button-container {
    display: flex;
    margin: 5px;
    height: 34px;
    align-items: center;
    border-radius: var(--size-5);
    background-color: var(--color-light);
    overflow: hidden;
}

.flyout .user-views .view-button-container input {
    background-color: transparent;
}

.flyout .user-views .active-view {
    background-color: var(--color-highlight2);
}

.flyout .user-views .active-view button {
    margin: 0;
    color: var(--text);
}

.flyout .user-views .active-view .highlight:hover {
    color: var(--color-light-grey-shaded);
}

.flyout .user-views .active-view .view-button:hover {
    color: var(--color-light-grey-shaded);
}

.flyout .user-viewsv .active-view .error:hover {
    color: var(--color-error-light);
}

.flyout .user-views button {
    border: none;
    background-color: transparent;
    color: var(--color-dark-grey);
    border-radius: 17px;
    height: 34px;
    min-width: 35px;
    padding: 0;
    transition: 0.1s ease;
    cursor: pointer;
}

.flyout .user-views .add-button {
    display: flex;
    margin: 5px;
    height: 34px;
    align-items: center;
    border-radius: var(--size-5);
    min-width: 0;
    color: var(--color-light-grey);
    font-size: var(--size-7);
    overflow: hidden;
}

.flyout .user-views .add-button ion-icon {
    font-size: 1em;
}

.flyout .user-views button:hover {
    color: var(--color-highlight);
}

.flyout .user-views .error:hover {
    color: var(--color-error-light);
}

.flyout .user-views .view-button {
    padding-left: 12;
    padding-right: 10;
}

.flyout .user-views .highlight {
    color: var(--color-dark-grey);
}

.flyout .content .scroll-view {
    min-height: 100%;
    height: calc(100vh - 135px);
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    margin-left: 35px;
}

.flyout .content .scroll-view ::-webkit-scrollbar-thumb {
    background: var(--color-dark-grey);
    border-radius: 3px;
}

.flyout .content .scroll-view ::-webkit-scrollbar-thumb:hover {
    background: var(--color-grey);
}

.flyout .view-link {
    color: var(--color-highlight2);
    text-decoration: none;
}

.flyout .view-link ion-icon {
    font-size: var(--size-5);
    margin-top: 4px;
    color: var(--color-highlight2);
}

/* #endregion */

/* #region blockeditor */
.block-editor-screen {
    padding-bottom: 200;
}

.add-block-icons {
    font-size: var(--size-7);
    color: var(--color-highlight2);
}

.add-block-icons:hover {
    color: var(--color-highlight);
}

.block-editor-icons {
    font-size: var(--size-6);
    color: var(--color-light-grey);
}

.components-list input {
    width: 130px;
    height: 2.3rem;
    /* min-height: 35px; */
    background-color: transparent;
    border: 1px solid var(--color-light-grey-shaded);
    transition: 0.1s ease;
    padding-left: 12;
    box-sizing: border-box;
    color: var(--text);
}

.components-list .dropdown {
    width: 130px;
    height: 2.3rem;
    /* min-height: 35px; */
    background-color: transparent;
    transition: 0.1s ease;
    box-sizing: border-box;
    color: var(--text);
    margin: 5px;
}

.components-list .dropdown .combo {
    background-color: transparent;
    height: 2.3rem;
    margin: 0;
    padding: 0;
    padding-left: 12;
}

.components-list .dropdown .options {
    width: 130px;
    font-size: 1rem;
}

.components-list .dropdown .input {
    margin: 0;
}

.block-editor-screen .got-input-container {
    margin: 0 auto 0 auto;
}

.block-editor-screen .components-list input:hover {
    border: 1px solid var(--color-light);
}

.block-editor-screen button {
    height: 35px;
    color: var(--color-light);
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 0.1s ease;
}

.block-editor-screen button:hover {
    color: var(--color-highlight2);
}

.block-editor-screen button:disabled {
    color: var(--color-light-grey);
}

.block-editor-screen .components-list {
    box-sizing: border-box;
    margin-top: 25px;
    margin-bottom: 20px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 5px;
    border-radius: var(--size-5);
    border-top: 1px solid var(--color-light-grey);
    border-bottom: 1px solid var(--color-light-grey);
    border-left: 1px solid var(--color-light-grey);
}

.block-editor-screen .components-list .buttons {
    display: flex;
    justify-content: flex-start;
}

.block-editor-screen .components-list .buttons button {
    margin-left: 0;
    padding-left: 0;
    margin-right: 10;
}

.block-editor-screen .components-list .buttons span {
    color: var(--color-highlight2);
}

.block-editor-screen .components-list .buttons span:hover {
    color: var(--color-highlight);
}

.block-editor-screen .component-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.block-editor-screen .component-inputs .button-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.block-editor-screen .component-inputs span {
    color: var(--color-light-grey);
    cursor: default;
    margin-right: 10;
}

.block-editor-screen .component-props {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
}

.block-editor-screen .component-buttons {
    display: flex;
    justify-content: flex-end;
    padding-left: 5;
}

.block-editor-screen .component-buttons button {
    border: 0;
    margin: 0;
    padding: 0;
    height: 35;
    width: 25;
    color: var(--color-light);
    cursor: pointer;
}

.block-editor-screen .component-buttons span {
    border: 0;
    color: var(--color-light);
    cursor: pointer;
    transition: 0.1s ease;
}

.block-editor-screen .component-buttons .error span {
    transition: 0.1s ease;
}

.block-editor-screen .component-buttons span:hover {
    color: var(--color-highlight2);
}

.block-editor-screen .component-buttons .error span:hover {
    color: var(--color-error-light);
}

.block-editor-screen .component-container {
    margin-bottom: 10;
    border-radius: var(--size-5) 0px 0px var(--size-5);
    /* border: 1px solid var(--color-light-grey); */
    border-right: 0px;
}

/* #endregion */

/* #region gotview */
.got-view-screen {
    padding-bottom: 200px;
}

.got-view-screen .save-button {
    border-radius: var(--size-5);
    background-color: var(--color-light-grey-shaded);
    display: flex;
    height: 34px;
    width: fit-content;
    padding: 5px;
    margin: 0 auto 0 auto;
    align-items: center;

    color: var(--text);
    overflow: hidden;
}

.got-view-screen .save-button ion-icon {
    font-size: var(--size-6);
    margin: 0 5px 0 10px;
}

.got-view-screen .save-button:hover {
    color: var(--color-highlight2);
}

/* #endregion */

/* #region tooltip */
[tooltip] {
    position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
    text-transform: none; /* opinion 2 */
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
}

[tooltip]::before {
    content: '';
    border: 5px solid transparent; /* opinion 4 */
    z-index: 1001; /* absurdity 1 */
}

[tooltip]::after {
    content: attr(tooltip); /* magic! */

    /* most of the rest of this is opinion */
    font-family: Helvetica, sans-serif;
    text-align: center;

    /*
      Let the content set the size of the tooltips
      but this will also keep them from being obnoxious
      */
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1ch 1.5ch;
    border-radius: 0.3ch;
    box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
    background: #333;
    color: #fff;
    z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
    display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
    display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^='up']::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #333;
}

[tooltip]:not([flow])::after,
[tooltip][flow^='up']::after {
    bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^='up']::before,
[tooltip][flow^='up']::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */
[tooltip][flow^='down']::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #333;
}

[tooltip][flow^='down']::after {
    top: calc(100% + 5px);
}

[tooltip][flow^='down']::before,
[tooltip][flow^='down']::after {
    left: 50%;
    transform: translate(-50%, 0.5em);
}

/* FLOW: DOWN-RIGHT */
[tooltip][flow^='down-right']::before {
    top: 100%;
    left: 50% !important;
    border-top-width: 0;
    border-bottom-color: #333;
}

[tooltip][flow^='down-right']::after {
    top: calc(100% + 5px);
}

[tooltip][flow^='down-right']::before,
[tooltip][flow^='down-right']::after {
    left: 120%;
    transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */
[tooltip][flow^='left']::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #333;
    left: calc(0em - 5px);
    transform: translate(-0.5em, -50%);
}

[tooltip][flow^='left']::after {
    top: 50%;
    right: calc(100% + 5px);
    transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^='right']::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #333;
    right: calc(0em - 5px);
    transform: translate(0.5em, -50%);
}

[tooltip][flow^='right']::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(0.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
    to {
        opacity: 0.9;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: 0.9;
        transform: translate(0, -50%);
    }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^='up']:hover::before,
[tooltip][flow^='up']:hover::after,
[tooltip][flow^='down']:hover::before,
[tooltip][flow^='down']:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^='left']:hover::before,
[tooltip][flow^='left']:hover::after,
[tooltip][flow^='right']:hover::before,
[tooltip][flow^='right']:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
}

/* #endregion */
