.landing-page a ion-icon {
    font-size: 1.3em;
    /* color: var(--color-light-grey); */
    --ionicon-stroke-width: 16px;
}
.landing-page a ion-icon:hover {
    color: var(--color-highlight2);
}

.landing-page .success {
    color: var(--color-success);
}

.landing-page .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
}

.landing-page {
    color: var(--color-light);
    height: 100vh;
    overflow-y: scroll !important;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.landing-page h1 {
    color: var(--text);
    margin-top: var(--size-3);
    margin-bottom: var(--size-2);
    font-weight: 400;
}
.landing-page h2 {
    color: var(--text);
    margin-top: var(--size-2);
    margin-bottom: var(--size-1);
    font-weight: 400;
}
.landing-page h3 {
    color: var(--text);
    margin-top: var(--size-1);
    margin-bottom: var(--size-1);
    font-weight: 600;
}
.landing-page ul {
    margin-top: var(--size-3);
}
.landing-page > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landing-page .content {
    max-width: 1200px;
    margin-top: var(--size-8);
}

.landing-page .explainer span.highlight-1 {
    display: inline-block;
    font-weight: 600;
    font-style: italic;
}
.landing-page .explainer span.highlight-2 {
    font-weight: 300;
}
.landing-page .explainer span.highlight-3 {
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
}
.landing-page .explainer span.highlight-4 {
    font-weight: 600;
    text-transform: uppercase;
}
.landing-page span.highlight-1 {
    font-weight: 700;
    font-style: normal;
}
.landing-page span.highlight-2 {
    font-style: italic;
}

.landing-page a {
    color: var(--color-highlight2);
    text-decoration: none;
}

.landing-page .jumbo {
    font-size: var(--size-7);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    text-align: center;
    position: relative;
    border-bottom: 1px solid var(--color-light-grey);
    margin-bottom: var(--size-7);
}
.landing-page .jumbo > div {
    flex: 1;
}
.landing-page .jumbo .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    padding-left: var(--size-6);
    background-color: var(--color-dark-shaded);
    box-shadow: 0 0 110px 120px var(--color-dark-shaded);
}
.landing-page .jumbo .title {
    font-size: var(--size-8);
    font-weight: 100;
}
.landing-page .jumbo .title .highlight-1 {
    height: 100%;
    color: var(--color-light);
    font-weight: inherit;
    font-style: inherit;
}
.landing-page .jumbo .title .highlight-2 {
    height: 100%;
    color: var(--color-highlight);
    font-weight: inherit;
    font-style: inherit;
}

.logo .highlight-1 {
    height: 100%;
    color: var(--color-light);
    font-weight: inherit;
    font-style: inherit;
}
.logo .highlight-2 {
    height: 100%;
    color: var(--color-highlight);
    font-weight: inherit;
    font-style: inherit;
}

.landing-page .jumbo .offset-graph {
    left: 0;
    z-index: 0;
    width: 150%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.landing-page .user-info {
    display: flex;
    gap: 1.5rem;
}

.landing-page .user-info a,
.landing-page .user-info button {
    color: var(--color-light);
    font-size: 1em;
}

.landing-page .solid {
    margin-top: 0;
    align-self: center;
    width: 95%;
    border: 0px;
    border-top: 1px solid var(--color-light-grey);
}

.landing-page .info-row {
    position: sticky;
    top: 0;
    background-color: var(--color-grey);
    z-index: 100;
    display: flex;
    border-top: 1px solid var(--color-light-grey);
    border-bottom: 1px solid var(--color-light-grey);
}
.landing-page .info-row .info-element {
    width: 18vw;
    justify-content: start;
}

.landing-page .sections {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.landing-page .sections .section {
    scroll-margin-top: 20vh;
    margin: 10px;
    text-align: left;
}
.landing-page .sections .section h1 {
    font-size: var(--size-7);
    text-align: center;
    margin-bottom: var(--size-6);
}
.landing-page .sections .section code {
    display: inline-block;
    font-size: 0.7em;
    border-radius: var(--size-3);
    padding: var(--size-2);
    background-color: var(--color-light-grey);
}
.landing-page .sections .section {
    scroll-margin-top: 20vh;
    margin: 10px;
    text-align: left;
}
.landing-page .sections .section .container {
    display: flex;
    flex-direction: column;
    gap: var(--size-8);
    align-items: center;
}
.landing-page .sections .section .parkett > div.horizontal:nth-child(2) {
    display: flex;
    flex-direction: row-reverse;
}
.landing-page .sections .section .container.horizontal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.landing-page .sections .section .demo {
    width: 450px;
    display: flex;
    padding: 2rem 3rem;
    justify-content: space-around;
}
.landing-page .sections .section .demo,
.landing-page .sections .section img {
    max-width: 450px;
    max-height: 300px;
    border: 1px solid var(--color-light-grey);
    border-radius: var(--size-6);
}
.landing-page .sections .section .card {
    margin: 1rem 10vw;
    border-radius: 10px;
    border-color: white;
    background-color: var(--color-dark);
    box-shadow: 0 0 1.5em 1em var(--color-dark-grey);
    padding: 4rem 5rem;
    transition: opacity 250ms ease-in, transform 250ms ease-in;
    opacity: 0;
    transform: translateX(15%);
}
.landing-page .sections .section .card.inverted {
    background-color: transparent;
    box-shadow: none;
    transform: translateX(0);
    transform: scale(0.85);
    transition: opacity 250ms ease-in, transform 250ms ease-in;
}
.landing-page .sections .section .card h1 {
    margin-top: 0;
}
.landing-page .sections .section .card span {
    display: inline-block;
}
.landing-page .sections .section .card span:last-child {
    margin-bottom: 0;
}
.landing-page .sections .section .card.appear {
    opacity: 1;
    transform: translateX(0);
    transform: scale(1);
}

.landing-page .badges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--size-6);
}
.landing-page .badges.horizontal {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: left;
}
.landing-page .badges .badge {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--size-6);
    align-items: center;
    text-align: center;

    min-width: 200px;
}
.landing-page .badges.horizontal .badge {
    flex-direction: row;
    align-items: center;
    text-align: left;
}
.landing-page .badges.horizontal .badge.small {
    align-items: flex-start;
}
.landing-page .badges.horizontal .badge > div {
    min-width: 200px;
    max-width: 400px;
}
.landing-page .badges.horizontal .badge > ion-icon {
    flex: 1;
    min-width: var(--size-6);
    max-width: var(--size-6);
}
.landing-page .badges .badge ion-icon {
    padding: 1rem;
    border: 1px solid var(--color-light-grey);
    border-radius: var(--size-8);
    background-color: var(--color-dark-grey);
    color: var(--color-highlight2);
    font-size: 4em;
    --ionicon-stroke-width: 12px;
}
.landing-page .badges .badge.silent ion-icon {
    padding: 0;
    border: none;
    border-radius: 0;
    background: none;
}
.landing-page .badges .badge.large ion-icon {
    font-size: var(--size-9);
    max-width: var(--size-9);
    --ionicon-stroke-width: 8px;
}
.landing-page .badges .badge.small ion-icon {
    font-size: 1em;
    --ionicon-stroke-width: 24px;
}

.landing-page .pricing {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--size-6);
    justify-content: space-evenly;
}

.landing-page .pricing h3 {
    display: flex;
    align-items: center;
}

.landing-page .pricing ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
}

.landing-page .pricing ul li {
    margin-top: var(--size-3);
}
/* 
.landing-page .pricing ul li span{
    display: block;
} */

.landing-page .pricing ul li ion-icon {
    /* float: left; */
    color: var(--color-highlight2);
    font-size: var(--size-7);
    margin-right: var(--size-3);
    vertical-align: middle;
    --ionicon-stroke-width: 20px;
}

.landing-page .pricing .box {
    display: flex;
    width: auto;
    flex-direction: column;
    /* min-width: 350px; */
}

.landing-page .pricing .package {
    /* border: 1px solid var(--color-highlight2); */
    align-self: flex-start;
    border-radius: var(--size-6);
    padding: var(--size-6);
}

.landing-page .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.landing-page .footer .content {
    display: flex;
    flex-direction: row;
    margin: 30px;
    padding-top: 30px;
    border-top: 1px solid var(--color-light-grey);
    flex: 1;
    justify-content: space-between;
}
.landing-page .footer .section h1 {
    text-align: left;
}
.landing-page .footer .content > div {
    display: flex;
    flex-direction: column;
    width: auto;
}
