/* Dark Orange Theme */
pre code {
    display: block;
    padding: 10px;
    background: var(--background);
    -webkit-text-size-adjust: none;
    cursor: text;
}

pre code *::selection,
.hljs::selection {
    background: var(--selection) !important;
    user-select: all !important;
}

.hljs {
    color: var(--text);
}

.hljs-string {
    color: var(--string);
}

.hljs-number {
    color: var(--number) !important;
}

.hljs-title {
    color: var(--title) !important;
}

.hljs-built_in {
    color: var(--built_in) !important;
}

.hljs-keyword {
    color: var(--keyword) !important;
}

.hljs-function > .hljs-keyword {
    color: var(--function) !important;
    font-style: italic;
}

.hljs-params {
    color: var(--params);
}

.hljs-comment {
    color: var(--comment);
}

.nodeContainer {
    background: transparent;
    width: 75vmin;
    height: 75vmin;
    font-family: Helvetica, sans-serif;
    text-align: left;
    cursor: default;
}

pre {
    overflow-wrap: anywhere;
    margin: 0;
}
