:root {
    --font-size: 20px;
    --color-dark: #0a0c10;
    --color-dark-shaded: #0a0c1044;
    --color-dark-grey: #0e1116;
    --color-grey: #171b21;
    --color-light-grey: #2f405c;
    --color-light-grey-shaded: #2f405cab;
    --color-light: #93a3bc;
    --color-lighter: #93a3bc40;
    --color-highlight: #1553b7;
    --color-highlight2: #246ee5;
    --color-highlight-second-light: #eeacff;
    --color-highlight-second: #be4bdb;
    --color-highlight-second-shaded-intense: #be4bdb44;
    --color-highlight-second-shaded: #be4bdb22;
    --color-success: #00a676;
    --color-error: #d70036;
    --color-error-light: #d70036dd;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.highlight-input {
    @apply focus:outline-none dark:bg-got-highlight-second-shaded dark:text-got-highlight-second-light dark:focus:bg-got-highlight-second-shaded-intense;
}
.highlight-input-nested {
    @apply focus:outline-none dark:text-got-highlight-second-light dark:focus:bg-got-highlight-second-shaded;
}

.has-tooltip .tooltip {
    @apply pointer-events-none absolute left-1/2 -translate-y-full -translate-x-1/2 whitespace-nowrap text-center opacity-0 transition-opacity;
}
.has-tooltip .tooltip.left {
    @apply left-0 translate-x-0;
}
.has-tooltip .tooltip.right {
    @apply left-full -translate-x-full;
}

.has-tooltip {
    @apply relative;
}
.has-tooltip:hover .tooltip {
    @apply z-[5000] opacity-100 delay-500;
}

.scrollbar::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    border: 0.2rem solid rgba(0, 0, 0, 0);
    background: var(--color-dark-shaded);
    background-clip: padding-box;
    border-radius: 100vh;
}

.scrollbar::-webkit-scrollbar-corner {
    background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: var(--color-dark);
}

.anim-list-open {
    animation-name: list-open;
    animation-duration: 300ms;
}

@keyframes list-open {
    from {
        overflow-y: hidden;
        overflow-x: visible;
        max-height: 1rem;
    }

    99% {
        overflow-y: hidden;
        overflow-x: visible;
        max-height: 100vh;
    }

    to {
        overflow-y: unset;
        overflow-x: visible;
        max-height: unset;
    }
}
